import COS from 'cos-js-sdk-v5';
import { CosConfig, getCosConfig } from './settings';
/** cos存储桶单例 */
let cosInstance: COS;

/** 获取cos实例 */
const getCosInstance = () => {
  const cosConfig = getCosConfig();
  if (!cosInstance) {
    cosInstance = new COS({
      SecretId: cosConfig.secretId,
      SecretKey: cosConfig.secretKey,
    });
    return cosInstance;
  }
  return cosInstance;
};

/** cos上传处理类 */
class CosStorage {
  cosInstance: COS;

  cosConfig: CosConfig;

  constructor() {
    this.cosInstance = getCosInstance();
    this.cosConfig = getCosConfig();
  }

  uploadFile(file: File, key: string) {
    return new Promise((resolve, reject) => {
      this.cosInstance.putObject(
        {
          Bucket: this.cosConfig.privateBucketName /* 必须 */,
          Region: this.cosConfig.cosRegion /* 存储桶所在地域，必须字段 */,
          Key: key /* 必须 */,
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          // onProgress: function(progressData) {
          //   resolve(JSON.stringify(progressData));
          // }
        },
        function (err, data) {
          if (err) {
            reject('上传失败');
          } else if (data.statusCode === 200) {
            resolve('上传成功');
          }
        },
      );
    }) as Promise<string>;
  }
}

export const cosStorage = new CosStorage();
