import { message, UploadFile } from 'ant-design-vue';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { download, get } from '@/lib/api';
import { cosStorage } from './cosStorage';
import { getProjectId } from './settings';
import { transByte } from './utils/transByte';
/** useCosStorage配置, 对该hooks返回的所有上传和删除功能生效 */
interface UseCosStorageConfig {
  maxFileSize?: number; // 限制上传的文件大小， 单位为字节
}

/** 单次上传的配置 */
interface UploadFileConfig {
  maxFileSize?: number; // 限制本次上传的文件大小， 单位为字节
  fileName?: string; // 为本次上传的文件重新命名
}

/** 通过fileKey获取资源的下载链接 */
export const getDownloadUrl = (fileKey: string) => {
  return get('/file/download/url', { key: fileKey }) as Promise<string>;
};

/**
 * 生成文件的cos存储key
 * 生成规则：(项目标识-test | 项目标识-prod)/<业务场景标识>/<用户userId>/<上传时间>/<唯一uuid>/ 文件名.文件后缀名
 * 示例：saas-test/doctor-profile/userId/2022-8-20/<唯一uuid>/ 文件名.文件后缀名
 * @param {String} bussinessKey 当前业务场景标识
 * @param {String} userId 当前用户id
 */
export const generateFileKey = (bussinessKey: string, fileName: string) => {
  const env = process.env.VUE_APP_Environment === 'production' ? 'prod' : 'test';
  const projectId = `${getProjectId()}-${env}`;
  const date = moment().format('YYYY-MM-DD');
  const uuid = uuidv4();
  return `${projectId}/${bussinessKey}/${date}/${uuid}/${fileName}`;
};

/**
 * cos存储相关hooks
 * @param {String} bussinessKey 当前业务场景标识
 * @param {number} config.maxFileSize 限制上传的文件大小， 单位为字节
 */
export const useCosStorage = (bussinessKey: string, config?: UseCosStorageConfig) => {
  /**
   * 上传文件到cos
   * @param file 上传的文件
   * @param uploadConfig.maxFileSize 限制本次上传的文件大小， 单位为字节
   * @param uploadConfig.fileName 为本次上传的文件重新命名
   * @return {Promise<String | undefined>} 返回上传成功的文件key。失败返回undefined
   */
  const uploadFile = async (file: File | UploadFile, uploadConfig?: UploadFileConfig) => {
    if (!bussinessKey) {
      throw new Error('请先设置config.bussinessKey');
    }
    // 判断文件大小是否超过限制
    const fileSize = uploadConfig?.maxFileSize ?? config?.maxFileSize;
    if (file.size && fileSize) {
      if (file.size > fileSize) {
        const sizeStr = transByte(file.size);
        message.error(`文件大小不能超过${sizeStr}`);
        return undefined;
      }
    }

    const fileName = uploadConfig?.fileName ?? file.name;
    const fileKey = generateFileKey(bussinessKey, fileName);
    const uploadRes = await cosStorage.uploadFile(file as File, fileKey);
    if (uploadRes) {
      return fileKey;
    }
    return undefined;
  };
  /**
   *
   * @param fileKey 要删除的文件的fileKey，通过后端接口获取
   */
  const downloadFile = async (fileKey: string) => {
    const url = await getDownloadUrl(fileKey);
    if (url) {
      const filename = fileKey.split('/').pop();
      download(url, {}, { filename });
    }
  };

  return {
    uploadFile,
    downloadFile,
    getDownloadUrl,
  };
};
