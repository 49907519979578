/**
 * cos存储桶相关配置
 * TODO: 前端不应存储明文，换为后端验证
 */
const cosConfig = {
  secretId: 'AKIDymMhndIitpVw73jAMIyPbXEgspWx96oG',
  secretKey: 'GG1WljcHhtqXzB8pHUSAcpnX2KPFrFPK',
  cosRegion: 'ap-guangzhou',
  publicBucketName: 'saas-1258165268',
  privateBucketName: 'saas-private-1258165268',
};
export type CosConfig = typeof cosConfig;
/** 获取cos存储桶相关配置 */
export const getCosConfig = () => cosConfig;

/** cos全局设置 */
export interface CosGlobalSetting {
  projectId: null | string | (() => string); // 项目标识
}

/** cos全局设置 */
const cosGlobalSetting: CosGlobalSetting = {
  projectId: null, // 项目标识
};
/** 设置cos全局配置 */
export const setCosGlobalSetting = (setting: CosGlobalSetting) => {
  const { projectId } = setting;
  if (projectId) {
    cosGlobalSetting.projectId = typeof projectId === 'string' ? projectId : projectId();
  } else {
    throw new Error('必须设置projectId和userId');
  }
};

/** 获取cos项目标识 */
export const getProjectId = () => {
  const { projectId } = cosGlobalSetting;
  if (projectId) {
    return typeof projectId === 'string' ? projectId : projectId();
  }
  throw new Error('未设置projectId');
};
